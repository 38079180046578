import { useContext } from 'react'
import { AuthenticationContext, ProfileInfo } from './AuthenticationProvider'

export const useProfileInfo = (): {
  profileInfo: ProfileInfo
  setProfileInfo: (_: ProfileInfo) => void
} => {
  const { profileInfo, setProfileInfo } = useContext(AuthenticationContext)
  return { profileInfo, setProfileInfo }
}
