/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
const CONFIG = window.__CONFIG__

// @ts-ignore
delete window.__CONFIG__

export const config: {
  API_URL: string
  GOOGLE_AUTH_CLIENT_ID: string
} = {
  API_URL: CONFIG.API_URL,
  GOOGLE_AUTH_CLIENT_ID: CONFIG.GOOGLE_AUTH_CLIENT_ID
}
