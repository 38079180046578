import { getGroupsUrl } from '../Groups'
import { createUrl } from '../Requests'

export interface IDialInfo {
  id: number
  title: string
  url: string
  order_idx: number
  image_file_name: string
}

export const EmptyDialId = -1
export const EmptyDialInfo: IDialInfo = {
  id: EmptyDialId,
  title: '',
  url: '',
  order_idx: -1,
  image_file_name: ''
}

export interface IImageInfo {
  id: string
  order_idx: number
  file_name: string
}

export const getDialsUrl = (groupId: number): string =>
  getGroupsUrl() + '/' + groupId + '/dials'

export const getDialUrl = (groupId: number, dialId: number): string =>
  getGroupsUrl() + '/' + groupId + '/dials/' + dialId
export const getImageUrl = (fileName: string): string => {
  if ('' !== fileName) {
    return createUrl('/images/' + fileName)
  } else {
    return createUrl('/images')
  }
}
