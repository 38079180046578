import { styled } from '@mui/material/styles'
import React from 'react'
import { AuthenticationProvider } from '../AuthenticationContext'
import { Groups } from '../Groups'
import { MessageProvider, MessageSnackBar } from '../MessageContext'

const PREFIX = 'App'

const classes = {
  body: `${PREFIX}-body`,
  root: `${PREFIX}-root`
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.body}`]: {},

  [`&.${classes.root}`]: {
    height: '100vH',
    backgroundImage: `url(${theme.custom.background.tile})`,
    backgroundRepeat: 'repeat'
  }
}))

export const App: React.FunctionComponent = () => {
  // Update the document body background using the browser API
  document.body.className = classes.body

  return (
    <Root className={classes.root}>
      <AuthenticationProvider>
        <MessageProvider>
          <Groups />
          <MessageSnackBar />
        </MessageProvider>
      </AuthenticationProvider>
    </Root>
  )
}
