import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useProfileInfo } from '../AuthenticationContext'
import { useMsg } from '../MessageContext'
import { httpGet } from '../Requests'
import { getDialsUrl, IDialInfo } from './request'

const defaultDials: IDialInfo[] = []

export const useDials = (
  groupId: number
): [IDialInfo[], Dispatch<SetStateAction<IDialInfo[]>>] => {
  const requestUrl = useMemo(() => getDialsUrl(groupId), [groupId])

  const [dials, setDials] = useState(defaultDials)
  const { setMsg } = useMsg()
  const { profileInfo } = useProfileInfo()

  useEffect(() => {
    async function fetchData() {
      await httpGet<IDialInfo[]>(requestUrl, profileInfo.token)
        .then((dials) => setDials(dials))
        .catch((error) =>
          setMsg({
            show: true,
            type: 'error',
            msg: error.message
          })
        )
    }

    fetchData()
  }, [requestUrl, setMsg, profileInfo])

  // custom handler for reacting on async state updates
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getDials = async (): Promise<IDialInfo[]> => {
    let state: unknown

    await setDials((currentState: IDialInfo[]) => {
      state = currentState

      return currentState
    })

    return state as IDialInfo[]
  }

  return [dials, setDials]
}
