import { IImageInfo } from '.'
import { getImageUrl } from './request'

export const addImage = async (
  image: File,
  token: string
): Promise<IImageInfo> => {
  const formData = new FormData()

  formData.append('file', image, image.name)

  try {
    const response = await fetch(`${getImageUrl('')}`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token
      },
      body: formData
    })

    if (response.ok) {
      const addedImage: IImageInfo = await response.json()

      if (addedImage) {
        return addedImage
      } else {
        return Promise.reject(
          new Error(`Uploading image "${image.name}" failed`)
        )
      }
    } else {
      const error = new Error(response.statusText)
      return Promise.reject(error)
    }
  } catch (error) {
    return Promise.reject(
      new Error(`Uploading image "${image.name}" failed: ` + error)
    )
  }
}
