import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Button, ImageList } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { useProfileInfo } from '../AuthenticationContext'
import { AddDial, Dial } from '../Dial'
import { DialPropertiesDialog } from '../DialPropertiesDialog'
import {
  EmptyDialId,
  EmptyDialInfo,
  getDialUrl,
  IDialInfo,
  useDials
} from '../Dials'
import { useMsg } from '../MessageContext'
import { httpWithBody } from '../Requests'

const PREFIX = 'DialsPage'

const classes = {
  root: `${PREFIX}-root`,
  imageList: `${PREFIX}-imageList`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.imageList}`]: {}
}))

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}))

export interface DialsPageProps {
  groupId: number
}

export const DialsPage: React.FunctionComponent<DialsPageProps> = ({
  groupId
}) => {
  const [dials, setDials] = useDials(groupId)
  const { profileInfo } = useProfileInfo()
  const { setMsg } = useMsg()

  // dial properties dialog handling
  const [dialPropertiesDialogOpen, setDialPropertiesDialogOpen] =
    useState(false)
  const [editDial, setEditDial] = React.useState<IDialInfo>(EmptyDialInfo)
  const openDialPropertiesDialg = () => {
    setDialPropertiesDialogOpen(true)
  }

  const closeDialPropertiesDialog = (addedDial: IDialInfo | null) => {
    setDialPropertiesDialogOpen(false)

    if (null != addedDial) {
      if (EmptyDialId === editDial.id) {
        setDials([...dials, addedDial])
        console.log('New dial submitted')
      } else {
        setDials(
          dials.map((dial) => (dial.id === addedDial.id ? addedDial : dial))
        )
        console.log('Existing dial updated')
      }
    } else {
      console.log('DialPropertiesDialog cancelled')
    }

    setEditDial(EmptyDialInfo)
  }

  // context menu handling
  const [menuDial, setMenuDial] = React.useState<null | IDialInfo>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const onMenu = (eventTarget: HTMLElement, dial: IDialInfo) => {
    setMenuDial(dial)
    setAnchorEl(eventTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
    // do not reset menuDial here, since it is required afterwards, e.g. in case of 'Edit' function, where it is used inside the DialPropertiesDialog
  }

  // 'Edit' context menu entry
  const onEdit = (): void => {
    closeMenu()
    if (null != menuDial) {
      setEditDial(menuDial)
      openDialPropertiesDialg()
    }
  }

  // 'Delete' context menu entry
  const [confirmDeleteDialTitle, setConfirmDeleteDialTitle] = React.useState('')

  const onDelete = (): void => {
    closeMenu()
    setConfirmDeleteDialTitle(menuDial?.title ?? '')
  }

  const commitDelete = (): void => {
    setConfirmDeleteDialTitle('')
    if (null != menuDial) {
      console.log('Dial "' + menuDial.title + '" to be deleted.')

      httpWithBody<IDialInfo>(
        getDialUrl(groupId, menuDial.id),
        profileInfo.token,
        '',
        'DELETE'
      )
        .then((dial) => {
          setDials(dials.filter((checkDial) => dial.id !== checkDial.id))
        })
        .catch((error) => {
          setMsg({
            show: true,
            type: 'error',
            msg: error.message
          })
        })
    }
  }

  const cancelDelete = () => {
    setConfirmDeleteDialTitle('')
  }

  return (
    <Root className={classes.root}>
      <DialPropertiesDialog
        open={dialPropertiesDialogOpen}
        onClose={closeDialPropertiesDialog}
        groupId={groupId}
        originalDial={menuDial}
        editDial={editDial}
        setEditDial={setEditDial}
      />
      <Dialog
        open={confirmDeleteDialTitle !== ''}
        onClose={cancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete dial '{confirmDeleteDialTitle}'?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={commitDelete}>Delete</Button>
          <Button onClick={cancelDelete} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <StyledMenu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault()
            onEdit()
          }}
        >
          <EditIcon fontSize="small" />
          Edit
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault()
            onDelete()
          }}
        >
          <DeleteIcon fontSize="small" />
          Delete
        </MenuItem>
      </StyledMenu>
      <ImageList
        rowHeight={150}
        cols={10}
        gap={5}
        className={classes.imageList}
      >
        {dials.map((dial) => (
          <Dial dial={dial} onMenu={onMenu} />
        ))}
        <AddDial onClick={openDialPropertiesDialg} />
      </ImageList>
    </Root>
  )
}
