import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import InputAdornment from '@mui/material/InputAdornment'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { compare } from 'fast-json-patch'
import React from 'react'
import { useProfileInfo } from '../AuthenticationContext'
import {
  addImage,
  EmptyDialId,
  getDialsUrl,
  getDialUrl,
  IDialInfo
} from '../Dials'
import { useMsg } from '../MessageContext'
import { httpWithBody } from '../Requests'

const PREFIX = 'DialPropertiesDialog'

const classes = {
  uploadButton: `${PREFIX}-uploadButton`
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.uploadButton}`]: {
    marginBottom: '25px'
  }
}))

export interface DialPropertiesDialogProps {
  open: boolean
  onClose(dialInfo: IDialInfo | null): void
  groupId: number
  originalDial: IDialInfo | null
  editDial: IDialInfo
  setEditDial(dialInfo: IDialInfo): void
}

export const DialPropertiesDialog: React.FunctionComponent<
  DialPropertiesDialogProps
> = (props) => {
  const [uploadFile, setUploadFile] = React.useState<File | null>()
  const { setMsg } = useMsg()
  const { profileInfo } = useProfileInfo()
  const isAddDial = EmptyDialId === props.editDial.id

  const commitDialProperties = (
    title: string,
    url: string,
    image_file_name: string
  ) => {
    if (isAddDial) {
      // REST call for adding new dial
      httpWithBody<IDialInfo>(
        getDialsUrl(props.groupId),
        profileInfo.token,
        JSON.stringify({
          title: title,
          url: url,
          image_file_name: image_file_name
        })
      )
        .then((dial) => props.onClose(dial))
        .catch((error) => {
          setMsg({
            show: true,
            type: 'error',
            msg: error.message
          })
          props.onClose(null)
        })
    } else {
      if (null != props.originalDial) {
        // determine JSON patch for update of existing dial
        const updateDial = {
          ...props.editDial,
          title: title,
          url: url,
          image_file_name: image_file_name
        }
        const jsonPatch = compare(props.originalDial, updateDial, false)
        //console.log(jsonPatch)

        // REST call for updating existing dial
        httpWithBody<IDialInfo>(
          getDialUrl(props.groupId, updateDial.id),
          profileInfo.token,
          JSON.stringify(jsonPatch),
          'PATCH'
        )
          .then((dial) => props.onClose(dial))
          .catch((error) => {
            setMsg({
              show: true,
              type: 'error',
              msg: error.message
            })
            props.onClose(null)
          })
      }
    }
  }

  const handleSubmit = () => {
    if (props.editDial.title && props.editDial.url) {
      if (uploadFile) {
        // commit dial with (new) image
        //console.log(uploadFile)

        addImage(uploadFile, profileInfo.token)
          .then((image) => {
            // add dial to group
            commitDialProperties(
              props.editDial.title,
              props.editDial.url,
              image.file_name
            )
          })
          .catch((error) => {
            setMsg({
              show: true,
              type: 'error',
              msg: error.message
            })
            props.onClose(null)
          })

        setUploadFile(null)
      } else {
        // commit dial without (new) image
        commitDialProperties(
          props.editDial.title,
          props.editDial.url,
          props.editDial.image_file_name
        )
      }
    }
  }

  const handleCancel = () => {
    props.onClose(null)
    setUploadFile(null)
  }

  const handleUploadFile = (fileList: FileList | null) => {
    if (null != fileList) {
      setUploadFile(fileList[0])
      props.setEditDial({
        ...props.editDial,
        image_file_name: fileList[0].name
      })
    }
  }

  const handleTitleChange = (e: any) => {
    props.setEditDial({
      ...props.editDial,
      title: e.target.value
    })
  }
  const handleUrlChange = (e: any) => {
    props.setEditDial({
      ...props.editDial,
      url: e.target.value
    })
  }

  return (
    <Root>
      <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{isAddDial ? 'Add Dial' : 'Edit Dial'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter title and URL for the dial. A dial image can be either
            uploaded from file or downloaded from an image url.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            value={props.editDial.title}
            onChange={handleTitleChange}
          />
          <TextField
            margin="dense"
            id="url"
            label="URL"
            type="url"
            fullWidth
            variant="standard"
            value={props.editDial.url}
            onChange={handleUrlChange}
          />
          <TextField
            margin="dense"
            id="imageUrl"
            label="Image"
            type="url"
            fullWidth
            variant="standard"
            value={props.editDial.image_file_name}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <label htmlFor="imageFile">
                    <input
                      style={{ display: 'none' }}
                      id="imageFile"
                      name="imageFile"
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleUploadFile(e.target.files)}
                    />

                    <CloudUploadIcon />
                  </label>
                </InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </Root>
  )
}
