import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useProfileInfo } from '../AuthenticationContext'
import { useMsg } from '../MessageContext'
import { httpGet } from '../Requests'
import { getGroupsUrl, IGroupInfo } from './request'
export const defaultGroups: IGroupInfo[] = []

export const useGroups = (
  initialState: IGroupInfo[] | (() => IGroupInfo[])
): [
  IGroupInfo[],
  Dispatch<SetStateAction<IGroupInfo[]>>,
  () => Promise<IGroupInfo[]>
] => {
  const requestUrl = useMemo(() => getGroupsUrl(), [])

  const [groups, setGroups] = useState(initialState)
  const { setMsg } = useMsg()
  const { profileInfo } = useProfileInfo()

  // effect for loading available groups from REST service
  useEffect(() => {
    async function fetchData() {
      await httpGet<IGroupInfo[]>(requestUrl, profileInfo.token)
        .then((groups) => setGroups(groups))
        .catch((error) =>
          setMsg({
            show: true,
            type: 'error',
            msg: error.message
          })
        )
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // The dependency array needs to remain empty, to avoid an infinite render loop in case of errors returned by the fetch groups request!

  // custom handler for reacting on async state updates
  const getGroups = async (): Promise<IGroupInfo[]> => {
    let state: unknown

    await setGroups((currentState: IGroupInfo[]) => {
      state = currentState

      return currentState
    })

    return state as IGroupInfo[]
  }

  return [groups, setGroups, getGroups]
}
