import { config } from '../App'

export const createUrl = (path: string): string => `${config.API_URL}${path}`

export async function httpGet<T>(
  request: RequestInfo,
  token: string
): Promise<T> {
  try {
    const response = await fetch(request, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })

    if (response.ok) {
      const jsonData = await response.json()
      const body: T = jsonData
      return body
    } else {
      return Promise.reject(new Error(response.statusText))
    }
  } catch (error) {
    let message
    if (error instanceof Error) message = error.message
    else message = String(error)

    console.log(message)
    return Promise.reject(new Error(message))
  }
}

export async function httpWithBody<T>(
  request: RequestInfo,
  token: string,
  body: string,
  method = 'POST'
): Promise<T> {
  const contentType =
    method === 'PATCH' ? 'application/json-patch+json' : 'application/json'
  try {
    const response = await fetch(request, {
      method: method,
      headers: {
        'Content-Type': `${contentType};charset=UTF-8`,
        Authorization: 'Bearer ' + token
      },
      body: body
    })

    if (response.ok) {
      const jsonData = await response.json()
      const body: T = jsonData
      if (body) {
        return body
      } else {
        return Promise.reject(new Error(`${method} "${body}" failed`))
      }
    } else {
      return Promise.reject(new Error(response.statusText))
    }
  } catch (error) {
    return Promise.reject(new Error(`${method} "${body}" failed: ` + error))
  }
}
