import AddIcon from '@mui/icons-material/Add'
import { AppBar, Avatar, Box, Tab, Tabs, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { AddGroupPage } from '../AddGroupPage'
import { useProfileInfo } from '../AuthenticationContext'
import { DialsPage } from '../DialsPage'
import { SettingsPage } from '../SettingsPage'
import { IGroupInfo } from './request'
import { defaultGroups, useGroups } from './useGroups'

const PREFIX = 'Groups'

const classes = {
  rightAligned: `${PREFIX}-rightAligned`,
  appBar: `${PREFIX}-appBar`
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.rightAligned}`]: {
    marginLeft: 'auto'
  },

  [`& .${classes.appBar}`]: {}
}))

function TabPanel(props: any) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

export const Groups: React.FunctionComponent = () => {
  const { profileInfo } = useProfileInfo()

  const [currentTab, setCurrentTab] = useState(0)

  function handleChange(event: React.ChangeEvent<unknown>, newValue: any) {
    setCurrentTab(newValue)
  }

  // https://medium.com/weekly-webtips/implementing-a-rest-api-with-react-hooks-using-patterns-2ea1476e2a05
  // https://devtrium.com/posts/react-typescript-how-to-type-hooks
  // https://medium.com/swlh/interacting-with-restful-apis-using-typescript-react-hooks-and-axios-part-1-af52920ae3e4

  const [groups, setGroups, getGroups] = useGroups(defaultGroups)

  const handleAddGroup = async (addedGroup: IGroupInfo) => {
    console.log('New group "' + addedGroup.title + '" submitted')

    // https://medium.com/ableneo/react-setstate-does-not-immediately-update-the-state-84dbd26f67d5
    //   src code: https://github.com/marcelmokos/react-set-state-get-state/blob/master/src/hooks.test.ts
    setGroups([...groups, addedGroup])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const currentGroups = await getGroups()

    // https://reactjs.org/docs/state-and-lifecycle.html
    //groups.push(addedGroup)
    setCurrentTab(addedGroup.order_idx - 1)
  }

  // Context für Error Handling
  // https://elisealcala.com/context-use-reducer-typescript/
  //const { state, dispatch } = useContext(AppContext)

  return (
    <Root>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {groups.map((group) => (
            <Tab label={group.title} key={group.id} />
          ))}
          <Tab icon={<AddIcon />} key="add" />
          <Tab
            className={classes.rightAligned}
            label={profileInfo.name}
            key="settings"
            icon={
              <Avatar alt={profileInfo.name} src={profileInfo.pictureUrl} />
            }
          />
        </Tabs>
      </AppBar>
      {groups.map((group) => (
        <TabPanel value={currentTab} index={group.order_idx - 1} key={group.id}>
          <DialsPage groupId={group.id} />
        </TabPanel>
      ))}
      <TabPanel value={currentTab} index={groups.length} key="add">
        <AddGroupPage onSubmit={handleAddGroup} />
      </TabPanel>
      <TabPanel value={currentTab} index={groups.length + 1} key="settings">
        <SettingsPage />
      </TabPanel>
    </Root>
  )
}
