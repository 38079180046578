import { Button, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import { OAuth2Client } from 'google-auth-library'
import React, { useEffect, useState } from 'react'
import { config } from '../App'
import { useProfileInfo } from './AuthenticationHook'

const PREFIX = 'LoginPage'

const classes = {
  root: `${PREFIX}-root`,
  formPaper: `${PREFIX}-formPaper`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '24px'
  },

  [`& .${classes.formPaper}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    padding: '10px',
    width: '250px',
    height: '150px',

    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    border: 'thin solid darkgray'
  }
}))

// taken from:
// https://dev.to/mremanuel/add-the-new-google-sign-in-to-your-react-app-p6m

export const LoginPage: React.FunctionComponent = () => {
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)
  const { setProfileInfo } = useProfileInfo()

  useEffect(() => {
    // https://developers.google.com/identity/gsi/web/guides/verify-google-id-token
    const client = new OAuth2Client(config.GOOGLE_AUTH_CLIENT_ID)

    async function verify(token: string) {
      const ticket = await client.verifyIdToken({
        idToken: token,
        audience: config.GOOGLE_AUTH_CLIENT_ID
      })
      const payload: any = ticket.getPayload()
      //const userid = payload['sub']
      // If request specified a G Suite domain:
      // const domain = payload['hd'];

      setProfileInfo({
        token: token,
        name: payload.name,
        pictureUrl: payload.picture
      })
    }

    const initializeGsi = () => {
      // Typescript will complain about window.google
      // Add types to your `react-app-env.d.ts` or //@ts-ignore it.
      if (!window.google || gsiScriptLoaded) return

      setGsiScriptLoaded(true)
      window.google.accounts.id.initialize({
        client_id: config.GOOGLE_AUTH_CLIENT_ID,
        callback: handleSignIn
      })
    }

    const handleSignIn = (res: CredentialResponse) => {
      if (!res.clientId || !res.credential) return

      verify(res.credential).catch(console.error)
    }

    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.onload = initializeGsi
    script.async = true
    script.id = 'google-client-script'
    document.querySelector('body')?.appendChild(script)

    return () => {
      // Cleanup function that runs when component unmounts
      window.google?.accounts.id.cancel()
      document.getElementById('google-client-script')?.remove()
    }
  }, [gsiScriptLoaded, setProfileInfo])

  return (
    <Root className={classes.root}>
      <Paper className={classes.formPaper}>
        <Button className={'g_id_signin'} />
      </Paper>
    </Root>
  )
}
