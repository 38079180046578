import React, { createContext, useState } from 'react'

/*
 * show : whether show or hide the message block
 * type : what theme is the message block
 * msg : message displaying on the message block
 *
 * You can add more field if you want
 */
export interface Msg {
  show: boolean
  type: 'error' | 'info' | 'success' | 'warning'
  msg: string
}

const defaultMsg = {
  show: false,
  type: 'success',
  msg: ''
} as Msg

export const MessageContext = createContext({
  msg: defaultMsg as Msg,
  setMsg: (_: Msg) => {
    // console.log('default setMsg called')
  }
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const MessageProvider = ({ children }: any): any => {
  const [msgState, setMsgState] = useState(defaultMsg)

  // MessageContext contains an object
  // and also a method for setState

  const contextValue = {
    msg: msgState,
    setMsg: (val: Msg) => {
      // console.log('setMsg called')
      setMsgState(val)
    }
  }
  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  )
}
