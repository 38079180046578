import { useContext } from 'react'
import { MessageContext, Msg } from './MessageProvider'

export const useMsg = (): {
  msg: Msg
  setMsg: (_: Msg) => void
} => {
  const { msg, setMsg } = useContext(MessageContext)
  return { msg, setMsg }
}
