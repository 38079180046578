import AddLinkIcon from '@mui/icons-material/AddLink'
import MenuIcon from '@mui/icons-material/Menu'
import {
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Link,
  Paper
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { getImageUrl, IDialInfo } from '../Dials'

const PREFIX = 'DialsPage'

const classes = {
  root: `${PREFIX}-root`,
  imageListItem: `${PREFIX}-imageListItem`,
  imageListItemPaper: `${PREFIX}-imageListItemPaper`,
  imageListItemLink: `${PREFIX}-imageListItemLink`,
  imageListItemBar: `${PREFIX}-imageListItemBar`,
  iconButton: `${PREFIX}-iconButton`,
  previewImage: `${PREFIX}-previewImage`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.imageListItem}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
    margin: '5px'
  },

  [`& .${classes.imageListItemPaper}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',

    padding: '10px',
    width: '200px',
    height: '150px',

    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    border: 'thin solid darkgray'
  },

  [`& .${classes.imageListItemLink}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',

    textDecoration: 'none',
    color: theme.palette.text.secondary
  },

  [`& .${classes.imageListItemBar}`]: {
    height: '34px',
    width: '200px',
    textAlign: 'center'
  },

  [`& .${classes.iconButton}`]: {
    height: '125px',
    width: '100px'
  },

  [`& .${classes.previewImage}`]: {
    maxHeight: '90px',
    maxWidth: '200px'
  }
}))

export interface DialProps {
  dial: IDialInfo
  onMenu(eventTarget: HTMLElement, dial: IDialInfo): void
}

export const Dial: React.FunctionComponent<DialProps> = ({ dial, onMenu }) => {
  const [style, setStyle] = useState({ display: 'none' })

  return (
    <Root className={classes.root}>
      <ImageListItem className={classes.imageListItem} key={dial.id}>
        <Paper className={classes.imageListItemPaper}>
          <Link
            className={classes.imageListItemLink}
            href={`${dial.url}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={getImageUrl(dial.image_file_name)}
              alt={dial.title}
              className={classes.previewImage}
            />
            <ImageListItemBar
              className={classes.imageListItemBar}
              title={dial.title}
              position="below"
              actionIcon={
                <div style={{ width: '30px' }}>
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`Dial Menu`}
                    style={style}
                    onClick={(e) => {
                      e.preventDefault()
                      onMenu(e.currentTarget, dial)
                    }}
                  >
                    <MenuIcon fontSize="small" />
                  </IconButton>
                </div>
              }
              onMouseEnter={(e) => {
                setStyle({ display: 'block' })
              }}
              onMouseLeave={(e) => {
                setStyle({ display: 'none' })
              }}
            />
          </Link>
        </Paper>
      </ImageListItem>
    </Root>
  )
}

export interface AddDialProps {
  onClick(): void
}

export const AddDial: React.FunctionComponent<AddDialProps> = ({ onClick }) => {
  return (
    <Root className={classes.root}>
      <ImageListItem className={classes.imageListItem} key="adddial">
        <Paper className={classes.imageListItemPaper}>
          <Link
            component="button"
            variant="body2"
            className={classes.imageListItemLink}
            onClick={() => {
              onClick()
            }}
          >
            <AddLinkIcon className={classes.iconButton} />
          </Link>
        </Paper>
      </ImageListItem>
    </Root>
  )
}
