import React, { createContext, useState } from 'react'
import { LoginPage } from './LoginPage'

export interface ProfileInfo {
  token: string
  name: string
  pictureUrl: string
}

const defaultProfileInfo = {
  token: '',
  name: '',
  pictureUrl: ''
} as ProfileInfo

export const AuthenticationContext = createContext({
  profileInfo: defaultProfileInfo as ProfileInfo,
  setProfileInfo: (_: ProfileInfo) => {
    // console.log('default setProfileInfo called')
  }
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AuthenticationProvider = ({ children }: any): any => {
  const [profileInfoState, setProfileInfoState] = useState(defaultProfileInfo)

  const contextValue = {
    profileInfo: profileInfoState,
    setProfileInfo: (val: ProfileInfo) => {
      // console.log('setProfileInfo called')
      setProfileInfoState(val)
    }
  }

  if (!profileInfoState.token) {
    return (
      <AuthenticationContext.Provider value={contextValue}>
        <LoginPage />
      </AuthenticationContext.Provider>
    )
  } else {
    return (
      <AuthenticationContext.Provider value={contextValue}>
        {children}
      </AuthenticationContext.Provider>
    )
  }
}
