import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { App } from './components/App'
import './index.css'
import * as serviceWorker from './serviceWorker'

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      background: {
        tile: string
      }
    }
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      background?: {
        tile?: string
      }
    }
  }
}
export const AppTheme = createTheme({
  palette: {
    mode: 'dark'
  },
  custom: {
    background: {
      tile: 'images/tile-stone-darkgray.png'
    }
  }
})

//console.log(AppTheme)

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={AppTheme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
