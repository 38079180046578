import React, { Alert, Snackbar } from '@mui/material'
import { useMsg } from './MessageHook'

export const MessageSnackBar = (): any => {
  const { msg, setMsg } = useMsg()

  // console.log(msg)
  return (
    <Snackbar
      open={msg?.show}
      autoHideDuration={3000}
      onClose={() => {
        setMsg({
          show: false,
          type: msg.type,
          msg: ''
        })
      }}
    >
      <Alert elevation={6} variant="filled" severity={msg.type}>
        {msg.msg}
      </Alert>
    </Snackbar>
  )
}
