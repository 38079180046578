import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Paper
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useProfileInfo } from '../AuthenticationContext'
import { getGroupsUrl, IGroupInfo } from '../Groups'
import { useMsg } from '../MessageContext'
import { httpWithBody } from '../Requests'

const PREFIX = 'AddGroupPage'

const classes = {
  root: `${PREFIX}-root`,
  formPaper: `${PREFIX}-formPaper`,
  formGrid: `${PREFIX}-formGrid`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  [`& .${classes.formPaper}`]: {
    padding: '10px',
    width: '250px',
    height: '150px',

    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    border: 'thin solid darkgray'
  },

  [`& .${classes.formGrid}`]: {
    height: '130px'
  }
}))

export interface AddGroupPageProps {
  onSubmit(groupInfo: IGroupInfo | null): void
}

export const AddGroupPage: React.FunctionComponent<AddGroupPageProps> = (
  props
) => {
  const [title, setTitle] = React.useState('New Group')
  const { setMsg } = useMsg()
  const { profileInfo } = useProfileInfo()

  const handleChangeTitle = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setTitle(event.target.value)
  }

  const handleSubmit = () => {
    console.log('Add new group: ' + title)

    httpWithBody<IGroupInfo>(
      getGroupsUrl(),
      profileInfo.token,
      JSON.stringify({
        title: title
      })
    )
      .then((data) => props.onSubmit(data))
      .catch((error) =>
        setMsg({
          show: true,
          type: 'error',
          msg: error.message
        })
      )
  }

  return (
    <Root className={classes.root}>
      <Paper className={classes.formPaper}>
        <form>
          <Grid
            className={classes.formGrid}
            container
            alignItems="center"
            justifyContent="space-between"
            direction="column"
            height={130}
          >
            <Grid item>
              <FormControl variant="standard">
                <InputLabel htmlFor="title">Title</InputLabel>
                <Input
                  autoFocus={true}
                  id="title"
                  value={title}
                  onChange={handleChangeTitle}
                  aria-describedby="title-helper-text"
                />
                <FormHelperText id="title-helper-text">
                  Enter the title/name for the new dial group.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </form>
      </Paper>
    </Root>
  )
}
